import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TextAreaInput, Warning } from '../../../../../utils/models/input.interface';
import { returnColorWarning, returnTextWarning, returnWarningIcon } from '../../../functions/utilities.service';
@Component({
  selector: 'textarea-input',
  templateUrl: './textarea-input.component.html',
  styleUrls: ['./textarea-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: TextareaInputComponent,
    },
  ],
})
export class TextareaInputComponent implements ControlValueAccessor {
  warningIconTextareaInput = returnWarningIcon;
  colorWarningTextareaInput = returnColorWarning;
  textWarningTextareaInput = returnTextWarning;

  @Input() textAreaInput: TextAreaInput;

  @Input() warning: Warning = {
    sucess: null,
    error: null,
    info: null,
    alert: null,
    load: null
  };

  @Output() valueEmitter: EventEmitter<string> = new EventEmitter<string>();

  writeValue(value: any): void {
    this.textAreaInput.value = value;
  }

  onChange = (value: any) => {
    return value;
  };

  onTouched = (value: unknown) => {
    return value;
  };

  registerOnChange(onChange: (value: any) => any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setNewValue() {
    this.onChange(this.textAreaInput.value);
    this.valueEmitter.emit(this.textAreaInput.value);
  }
}
