import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'check-list',
  templateUrl: './check-list.component.html',
  styleUrls: ['./check-list.component.scss']
})
export class CheckListComponent implements OnInit {
  @Input() listItens;
  @Input() minHeigth;
  @Input() maxHeigth;

  @Output() checkedItens: EventEmitter<any> = new EventEmitter<any>();
  @Output() checkedItem: EventEmitter<any> = new EventEmitter<any>();

  selectedItems: string[] = [];

  checkAll = false;

  ngOnInit() {
    if (this.listItens === undefined) {
      this.listItens = [{ name: 'Option' }, { name: 'Item' }, { name: 'Outro Item' }, { name: 'Another Item' }]
    }
  }

  isSelected(option: string) {
    return this.selectedItems.includes(option);
  }

  checkSelection(option: string, event) {
    if (this.isSelected(option)) {
      this.selectedItems = this.selectedItems.filter(item => item !== option);
    } else {
      this.selectedItems.push(option);
    }
    this.checkedItem.emit(option)
    this.checkedItens.emit(this.listItens)
  }

  checkAllItens(e) {
    if (e) {
      this.listItens.forEach((element: any) => {
        element.checked = true;
        this.selectedItems.push(element)
      });
      this.checkedItens.emit(this.listItens)
    } else {
      this.listItens.forEach((element: any) => {
        element.checked = false;
      });
      this.checkedItens.emit(this.listItens)
    }
  }

}
