import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ButtonInduxtry } from '../../../../../utils/models/input.interface';

@Component({
  selector: 'button-induxtry',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit {
  @Input() buttonProperties: ButtonInduxtry;

  @Output() action: EventEmitter<any> = new EventEmitter();

  ngOnInit() {
    if (this.buttonProperties?.customStyle === undefined || this.buttonProperties?.customStyle === null) {
      this.buttonProperties.customStyle = 'primary';
    }

    if(this.buttonProperties?.showLabel === undefined || this.buttonProperties?.showLabel === null ) {
      this.buttonProperties.showLabel = true;
    }
  }

  onButtonClicked(params: any, key?: string) {
    this.action.emit([params, key]);
  }
}
