import { Component, Input, OnInit } from '@angular/core';
import { MenuLinkMobile, MenuMobile } from '../../../../../utils/models/layout.interface';
import { Router } from '@angular/router';

@Component({
  selector: 'menu-mobile',
  templateUrl: './menu-mobile.component.html',
  styleUrls: ['./menu-mobile.component.scss']
})
export class MenuMobileComponent implements OnInit {
  @Input() menus: MenuMobile;

  primaryRouters: MenuLinkMobile[];
  secondaryRouters: MenuLinkMobile[] | [];
  open: boolean;

  constructor(public router: Router){

  }

  ngOnInit(): void {
    this.formatRoutersMenu();
  }

  handleClickRoute(route, seconday?) {
    if(route === '/manual'){
      let newTab = window.open();
      newTab.opener = null;
      newTab.location = 'https://dev.azure.com/enacom/ENACOM/_wiki/wikis/ENACOM.wiki/5894/Manual-do-usu%C3%A1rio-do-gerenciador-de-testes'
    }else{
    if (`/${route}` === this.router.url) {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate([route]);
      });
    }
    if(seconday){
      this.toggleOpenMenu();
    }
    // this.handleClick(event);
    }
  }



  formatRoutersMenu() {
    this.primaryRouters = [ ...this.menus.primary ];
    this.secondaryRouters = (this.menus.secondary ? [ ...this.menus.secondary ] : []);
    if (this.primaryRouters.length >= 5 && !(this.primaryRouters.length === 5 && this.secondaryRouters.length === 0)) {
      this.secondaryRouters = (this.menus.secondary ? [ ...this.menus.secondary ] : []);
      const beforePrimaryArray = [ ...this.primaryRouters ];
      for (let index = (this.primaryRouters.length - 1); index >= 4; index--) {
        this.secondaryRouters.push(beforePrimaryArray[index]);
        this.primaryRouters.splice(index, 1);
      }
    }
  }

  toggleOpenMenu() {
    this.open = !this.open;
  }

  closeMenu(event) {
    if (event.currentTarget === event.target) {
      this.toggleOpenMenu();
    }
  }
}
