import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import { DividerComponent } from '../divider/divider.component';
import { ButtonInduxtry } from '../../../../../utils/models/input.interface';

import SwiperCore, { Navigation, Pagination } from 'swiper';
import { SwiperComponent } from 'swiper/angular';

// install Swiper modules
SwiperCore.use([Navigation, Pagination]);

@Component({
  selector: 'cards',
  templateUrl: './cards.component.html',
  styleUrls: ['./cards.component.scss'],
  // encapsulation: ViewEncapsulation.None
})
export class CardsComponent implements OnInit, OnChanges {

  @ViewChild('divider') dividerComponent: DividerComponent;

  @Input() tag: string;
  @Input() title: string;
  @Input() divider = true;
  @Input() width: string | null;
  @Input() height: string | null;
  @Input() border: string | null;
  @Input() marginTop: string | null;
  @Input() disablePicture: boolean;
  @Input() icon: string;

  @Input() element: any;
  @Input() class: string;


  @Input() footerButton: ButtonInduxtry[] | null;
  @Output() buttonValue: EventEmitter<string> = new EventEmitter<string>();
  @Output() picture: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild(SwiperComponent) swiper: SwiperComponent;

  constructor(private readonly elementRef: ElementRef) { }

  ngOnInit(): void {
    if (this.tag === '') {
      this.tag = 'div';
    }
    this.checkCount()
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.toReplace()
  }

  ngAfterViewInit() {

      this.toReplace();

  }

  ngDoCheck() {
    this.checkCount()
  }

  checkCount() {
    if (this.element && this.element?.length >= 5) {
      setTimeout(() => {
        const swiperPagination = document.querySelector(".swiper-pagination")
        if (swiperPagination) {
          this.addCssInSwiperElement(swiperPagination.lastElementChild);
          this.addCssInSwiperElement(swiperPagination.firstElementChild);
        }
      }, 500)
    }
  }

  addCssInSwiperElement(element) {
    if (element.classList.contains("swiper-pagination-bullet-active")) {
      if (element instanceof HTMLElement) {
        element.style.width = "12px";
        element.style.height = "12px";
        element.style.backgroundColor = "#000000";
        element.style.marginBottom = "0px";
      }
    } else  {
      if (element instanceof HTMLElement) {
        element.style.width = "4px";
        element.style.height = "4px";
        element.style.marginBottom = "2px";
        element.style.backgroundColor = "#949494";
      }
    }
  }

  toReplace(){
    if (this.element) {
      for (let i = 0; i < this.element?.length; i++) {
        const div = this.elementRef.nativeElement.querySelector(`#${this.class}${i}`);
        if (div) {
          div.innerHTML = '';
          div.appendChild(this.element[i]);
        }
      }
    }
  }


  genericButton(event) {
    this.buttonValue.emit(event[1])
   }

   printCard(){
    this.picture.emit(true)
   }

  heightContainer(height) {
    const numberValue = parseInt(height, 10);
    const numberResults = numberValue - 60;

    return (numberResults + 'px');
  }
}
