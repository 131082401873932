import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input, OnDestroy,
  OnInit,
  OnChanges,
  Optional,
  Output,
  Self,
  ViewChild,
  ChangeDetectorRef
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  NgControl,
  Validators
} from '@angular/forms';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MatFormFieldControl } from '@angular/material/form-field';
import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { MatSelect } from '@angular/material/select';


import { RequestAttribute, RequestParams } from '../../../../../utils/models/http.interface';
import { HttpService } from '../../../../service/http/http.service';
import { environment } from '../../../../../environments/environment';

export function validateCounterRange(c: FormControl) {
  const err = {
    rangeError: {
      given: c.value
    }
  };
  return (c.value === undefined || c.value === null) ? err : null;
}

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'select-infinite-scroll-search',
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: SelectInfiniteScrollSearchComponent
    }],
  templateUrl: './select-infinite-scroll-search.component.html',
  styleUrls: ['./select-infinite-scroll-search.component.scss']
})
export class SelectInfiniteScrollSearchComponent implements OnInit, OnChanges,
  MatFormFieldControl<any>, ControlValueAccessor, AfterViewInit, OnDestroy, Validators {
  private readonly apiUrl: string = environment.apiUrl;

  stateChanges = new Subject<void>();

  @Input()
  set options(arr: any[]) {
    if (arr) {
      this._options = [...arr];
      this._options = this._options.filter(o => !!o);
      this._oprtionOriginal = [...arr];
      this._oprtionOriginal = this._oprtionOriginal.filter(o => !!o);
    }
  }

  @Input()
  set value(obj: any | null) {
    this._value = obj;
  }
  get value(): any | null {
    if (this._value) {
      return this._value;
    } else {
      return 'teste';
    }
  }

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  get errorState() {
    const controlDirectiveAux: any = this.controlDirective;
    let parent = controlDirectiveAux?._parent;
    while (parent?._parent) {
      parent = parent._parent;
    }
    if (parent?.submitted) {
      return !!this.controlDirective?.invalid && !this.controlDirective.disabled;
    } else {
      return !!this.controlDirective?.invalid && this.touched;
    }
  }
  @Input()
  get placeholder() {
    return this._placeholder;
  }
  set placeholder(plh) {
    this._placeholder = plh;
    this.stateChanges.next();
  }

  get empty() {
    return !this._value;
  }

  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.parts.disable() : this.parts.enable();
    this.stateChanges.next();
  }

  constructor(
    private readonly httpService: HttpService,
    private readonly fm: FocusMonitor,
    private readonly elRef: ElementRef<HTMLElement>,
    @Optional() @Self() public controlDirective: NgControl,
    private readonly cdRef: ChangeDetectorRef) {
    const sleepTime = 300;
    this.sleep(sleepTime)
      .then(() => {
        if (!this.internalFilter && !!this.endpoint && !!this._value) {
          if (this.returnVariable) {
            this.httpService.genericGetSelectInfinite<any>((this.urlApi ?? this.apiUrl), this.endpoint, this._value)
              .subscribe((object: any[] | any) => {
                this._options.unshift(object);
                const index = this._options.findIndex((option: any, i: number, options: any[]) => {
                  return (option[this.returnVariable] === options[0][this.returnVariable] && i !== 0);
                });
                if (index !== -1) {
                  this._options.splice(index, 1);
                }
              });
          } else {
            this.httpService.genericGetSelectInfinite<any>((this.urlApi ?? this.apiUrl), this.endpoint, this._value.id)
              .subscribe((object: any[] | any) => {
                this.writeValue(object);
                this._options.unshift(object);
                const index = this._options.findIndex((option: any, i: number, options: any[]) => {
                  return (option.id === options[0].id && i !== 0);
                });
                if (index !== -1) {
                  this._options.splice(index, 1);
                }
              });
          }
        }
      });
    fm.monitor(elRef.nativeElement, true).subscribe(origin => {
      this.focused = !!origin;
      this.stateChanges.next();
    });
    if (this.controlDirective) {
      this.controlDirective.valueAccessor = this;
    }
  }

  static nextId = 0;

  _options;
  _oprtionOriginal;

  @Input() internalFilter: false;

  @Input() disabledOptions: any;
  @Input() urlApi: string;
  @Input() endpoint: string;
  @Input() label: string;
  @Input() multiple: boolean;
  @Input() enableInfScroll = true;
  @Input() labelShow: string;
  @Input() labelShowPref: string;
  @Input() labelShowSuf: string;
  @Input() labelShowSearch: string;
  @Input() parts;
  @Input() hasCount: boolean;
  @Input() returnVariable: string = undefined;
  @Input() alphabeticalOrder = false;

  @Input() inputSearch: string = undefined;
  @Input() filterSearch = true;
  @Input() hasEmptyOption: false;
  @Input() isTableFilter = false;
  @Input() last = false;
  @Input() filterName: string;
  @Input() filterAllOptionsName = 'Marcar todas opções';

  @Output() selectionChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectionTouched: EventEmitter<any> = new EventEmitter<any>();
  @Output() optionEmmiter: EventEmitter<any> = new EventEmitter<any>();
  @Output() inputSearchEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() clearFilter: EventEmitter<any> = new EventEmitter<any>();
  @Output() filterEvent: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('multiSelect') multiSelect: MatSelect;

  complete = false;

  touched = false;

  @Input() attributes: RequestAttribute[] = [];
  _value: any = [];
  _required = false;
  _placeholder: string;


  ngControl: NgControl = null;
  focused = false;

  @HostBinding() id = `example-tel-input-${SelectInfiniteScrollSearchComponent.nextId++}`;
  @HostBinding('attr.aria-describedby') describedBy = '';
  private _disabled = false;
  @Input() disabledAux = false;
  // tslint:disable-next-line: variable-name
  private readonly _onDestroy = new Subject<void>();

  public formAux: FormControl = new FormControl();

  loading = false;
  requestParams: RequestParams = {
    sort: '',
    order: '',
    page: 1,
    limit: 30,
  };

  initialData: any = [];
  restore = true;

  AttLabs = false;
  atualizarLabelShowAtt = false;
  atualizarLabelShowPrefAtt = false;
  atualizarLabelShowSufAtt = false;
  blacklist= false

  returnAllOptionText(): string {
    if(this._value.indexOf('allOpt')!==-1){
      return this.filterAllOptionsName.replace('Marcar', 'Desmarcar');
    }else{
      return this.filterAllOptionsName.replace('Desmarcar', 'Marcar');
    }
  }


  filtrar() {
    let temp = []
    if(this._value.indexOf('allOpt')!==-1){
      temp = this._oprtionOriginal.filter(element=>{
        return !this._value.some(element2=>{
          return element2===element
        })
      })
      temp.push('allOpt')
    }else{
    temp = this._oprtionOriginal.filter(element=>{
      return this._value.some(element2=>{
        return element2===element
      })
    })
  }
    this.filterEvent.emit({checkedValue: this._value, emitValue: {filter:temp} });
    this.restore = false;
    this.multiSelect.toggle();
  }

  limpar() {
    this.clearOptions();
    this.clearFilter.emit();
    this.multiSelect.toggle();
  }

  clearOptions() {
    this.writeValue([]);
    this.stateChanges.next();
    this.restore = false;
  }

  validate(c: AbstractControl): { [key: string]: any } {
    // Here we call our static validator function
    return this.required ? Validators.required(c) : null;
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  clearSelection() {
    this._value = null;
    this._options = [];
  }

  onContainerClick(event: MouseEvent) {
    if ((event.target as Element).tagName.toLowerCase() !== 'div') {
      this.elRef.nativeElement.querySelector('div').focus();
    }
  }

  public setDisabledState(isDisabled: boolean): void {
    this._disabled = isDisabled;
    this.disabledAux = isDisabled;
  }

  writeValue(value: any) {
    this._value = value;
    this.cdRef.detectChanges();
  }

  propagateChange = (_: any) => { };

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() { }

  ngOnDestroy() {
    this.stateChanges.complete();
  }

  ngAfterViewInit(): void {
    this.controlDirective?.valueChanges.subscribe(() => {
      this.setTouched();
    });

    if (this.isTableFilter) {
      this.multiSelect.openedChange.subscribe((value) => {
        if (value) {
          this.initialData = this._value;
        }
        if (!value && this.restore) {
          this._value = this.initialData;
          this.propagateChange(this._value);
        }
        this.restore = true;
      });
    }
  }

  select(e) {
    if(this.multiple && !this.isTableFilter) {
      // this._value.map(e => e.check = true);
    }
    this.propagateChange(this._value);
    this.selectionChange.emit(this._value);
  }

  clickAllOpt() {
    const val = (this._value.indexOf('allOpt') !== -1 ? [...this._options, 'allOpt'] : []);
    this.writeValue(val);
  }

  setTouched() {
    this.touched = true;
    this.selectionTouched.emit();
    this.stateChanges.next();
  }

  infiniteScroll() {
    if (!this.loading && this.enableInfScroll && !this.internalFilter) {
      this.loading = true;
      this.requestParams.order = this.alphabeticalOrder ? 'asc' : '';
      this.requestParams.sort = this.alphabeticalOrder ? this.labelShow : '';
      this.requestParams.page++;
      this.complementsInfiniteScroll();
    }
  }

  complementsInfiniteScroll() {
    const attributesRequest = this.attributes.filter(a => a.value);
    if (!this.formAux.value && !this.complete) {
      this.complementsInfiniteScroll1(attributesRequest);
    }
  }

  complementsInfiniteScroll1(attributesRequest) {
    this.httpService.genericGetSelectFiltroScroll<[]>((this.urlApi ?? this.apiUrl), this.endpoint, this.requestParams, attributesRequest)
      .subscribe((obj: any[] | any) => {
        this.loading = false;
        if (this.hasCount) {
          const index = (obj.rows).findIndex((option: any, i: number) => {
            return (option.id === this._options[0].id);
          });
          if (index !== -1) {
            (obj.rows).splice(index, 1);
          }
          this._options = this._options.concat(obj.rows);
          if (obj.rows.length === 0) {
            this.complete = true;
          }
        } else {
          const index = obj.findIndex((option: any, i: number) => {
            return (option.id === this._options[0].id);
          });
          if (index !== -1) {
            obj.splice(index, 1);
          }
          this._options = this._options.concat(obj);
          if (obj.length === 0) {
            this.complete = true;
          }
        }
        this.optionEmmiter.emit(this._options);
      });
  }

  returnIfOptionIsDisabled(option: any) {
    if (this.disabledOptions && this.disabledOptions.indexOf(+option) !== -1) {
      return true;
    } else {
      return false;
    }
  }

  ngOnChanges() {
    if (this.inputSearch) {
      this.formAux.setValue(this.inputSearch);
    }
  }

  atualizarAtt(param, value) {

    if(this.attributes.length) {
      const pos = this.attributes.findIndex((object) => {
        return object.param === param;
      });

      if(pos === -1) {

        this.attributes.push({
          param: param,
          value: value,
        });

      } else {
        this.attributes[pos].value = value;
      }

    } else{
      this.attributes.push({
        param: param,
        value: value,
      });
    }
  }

  ngOnInit() {
    const sleepTime = 300;
    this.formAux.valueChanges.pipe(
      takeUntil(this._onDestroy),
      debounceTime(sleepTime),
      distinctUntilChanged()
    ).subscribe((subs) => {
      this.loading = true;
      if (!this.internalFilter) {
        if(this.labelShowSearch) {
          this.atualizarAtt(this.labelShowSearch, this.formAux.value);
        }
        if(this.labelShow) {
          this.atualizarAtt(this.labelShow, this.formAux.value);
        }
        if(this.labelShowPref) {
          this.atualizarAtt(this.labelShowPref, this.formAux.value);
        }
        if(this.labelShowSuf) {
          this.atualizarAtt(this.labelShowSuf, this.formAux.value);
        }

        this.requestParams.page = 1;
        this.requestParams.order = this.alphabeticalOrder ? 'asc' : '';
        this.requestParams.sort = this.alphabeticalOrder ? this.labelShow : '';
        this.httpService.genericGetSelectFiltroScroll<[]>((this.urlApi ?? this.apiUrl), this.endpoint, this.requestParams, this.attributes)
          .subscribe((option: any[] | any) => {
            if (this.hasCount) {
              this._options = option.rows;
            } else {
              this._options = option;
            }
            this.optionEmmiter.emit(this._options);
            this.inputSearchEmitter.emit(this.formAux.value);
            this.loading = false;
          });
      } else {
        if (!!this.formAux.value && this.labelShow) {
          this._options = this._oprtionOriginal
            .filter((option: string) => option[this.labelShow].toString().toLowerCase().includes(this.formAux.value.toLowerCase()));

        } else if (!!this.formAux.value) {
          this._options = this._oprtionOriginal
            .filter((option: string) => option.toString().toLowerCase().includes(this.formAux.value.toLowerCase()));

        } else {
          this._options = [...this._oprtionOriginal];
        }

        this.loading = false;
      }
    });
  }

  sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  i = 0;

  compare(arr1, arr2) {
    if (arr1 && arr2) {
      return (arr1.length === arr2.length) && arr1.every((element, index) => {
        return element === arr2[index];
      });
    }
    return false;
  }

  compareFn(itemFilter: any) {
    if (itemFilter['check']) {
      return itemFilter['check']
    }
  }

  returnLabel(op) {
    if (!this.isTableFilter) {
      if(this.labelShowPref && this.labelShow && this.labelShowSuf) {
        return `${op[this.labelShowPref]} ${op[this.labelShow]} ${op[this.labelShowPref]}`
      } else if(this.labelShowPref && this.labelShow) {
        return `${op[this.labelShowPref]} ${op[this.labelShow]}`
      } else if(this.labelShow && this.labelShowSuf) {
        return `${op[this.labelShow]} ${op[this.labelShowPref]}`
      } else if(this.labelShow) {
        return op[this.labelShow]
      }
    }
    switch(op){
      case "EXISTENT":
        return 'Existente'
      case "FEASIBLE":
        return 'Factível'
      case "UNFEASIABLE":
        return 'Infactível'
      case "REASONABLE":
        return 'Razoável'
      case "REASONING":
        return 'Executando'
      case "UNREASONABLE":
        return 'Irrazoável'
      case "TIMEOUTED":
        return 'Timeouted'
    }
    return op;
  }

}
