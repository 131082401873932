import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy } from '@angular/core';
import { MatCalendar } from '@angular/material/datepicker';
import { Subject } from 'rxjs';
import { DateAdapter, MatDateFormats, MAT_DATE_FORMATS } from '@angular/material/core';
import { takeUntil } from 'rxjs/operators';

const STYLE_CALENDAR = {
  DAY: 'month',
  MONTH: 'year',
  YEAR: 'multi-year'
}

@Component({
  selector: 'header-picker',
  templateUrl: './header-picker.component.html',
  styleUrls: ['./header-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderPickerComponent<Moment> implements OnDestroy {

  private readonly _destroyed = new Subject<void>()
  private _timeOut;

  constructor(
    private readonly calendar: MatCalendar<Moment>,
    private readonly dateAdapter: DateAdapter<Moment>,
    @Inject(MAT_DATE_FORMATS) private readonly dateFormats: MatDateFormats,
    cdr: ChangeDetectorRef
  ) { 
    calendar.stateChanges.pipe(takeUntil(this._destroyed))
      .subscribe(() => cdr.markForCheck());
  }

  ngOnDestroy(): void {
    this._destroyed.next();
    this._destroyed.complete();
  }

  get periodLabel() {
    if (this.calendar.currentView === STYLE_CALENDAR.DAY) {
      return `${this.dateAdapter.format(this.calendar.activeDate,'MMMM')} 
        de ${this.dateAdapter.format(this.calendar.activeDate, 'YYYY')}`;
    } else {
      return this.dateAdapter.format(this.calendar.activeDate,'YYYY');
    }
  }

  get showHeader() {
    if (this.calendar.currentView !== STYLE_CALENDAR.YEAR) {
      return true;
    }
    return false;
  }

  nextView() {
    switch (this.calendar.currentView) {
      case STYLE_CALENDAR.DAY:
        this.calendar.currentView = 'year';
        break;
      case STYLE_CALENDAR.MONTH:
        this.calendar.currentView = 'multi-year';
        break;
      default:
        break;
    }
  }

  capitalizeMonth() {
    const content = document.getElementsByClassName('mat-calendar-body-cell-content') as HTMLCollectionOf<HTMLElement>;
    Array.from(content).forEach((month) => {
      month.innerText = month.innerText.toLowerCase();
      month.innerText = month.innerText.charAt(0).toUpperCase() + month.innerText.slice(1);
    });
    clearTimeout(this._timeOut);
  }

  previousClicked() {
    if (this.calendar.currentView !== STYLE_CALENDAR.YEAR) {
      if (this.calendar.currentView === STYLE_CALENDAR.DAY) {
        this.calendar.activeDate = this.dateAdapter.addCalendarMonths(this.calendar.activeDate, -1)
      } else {
        this.calendar.activeDate = this.dateAdapter.addCalendarYears(this.calendar.activeDate, -1);
        this._timeOut = setTimeout(this.capitalizeMonth, 1);
      }
    }
  }
  
  nextClicked() {
    if (this.calendar.currentView !== STYLE_CALENDAR.YEAR) {
      if (this.calendar.currentView === STYLE_CALENDAR.DAY) {
        this.calendar.activeDate = this.dateAdapter.addCalendarMonths(this.calendar.activeDate, 1)
      } else {
        this.calendar.activeDate = this.dateAdapter.addCalendarYears(this.calendar.activeDate, 1);
        this._timeOut = setTimeout(this.capitalizeMonth, 1);
      }
    }
  }
}
