import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  isAuthenticated(): Promise <boolean> {
    return new Promise((resolve) => {
      const user = this.getAuthorizationToken();
      if (user) {
        resolve(true);
      }
      resolve(false);
    });
  }

  saveLocalStorage(state): Promise<any> {
    return new Promise( (resolve) => {
      localStorage.setItem('login', state);
      localStorage.setItem('name', 'ENACOM');
      resolve(true);
    });
  }

  removeLocalStorage(): Promise<any> {
    return new Promise<any>((resolve) => {
      sessionStorage.clear();
      localStorage.clear();
      resolve(true);
    });
  }

  getAuthorizationToken() {
    return localStorage.getItem('login');
  }

}
