import {
  Component,
  OnInit,
  ViewChild,
  Input,
  OnDestroy,
  EventEmitter,
  Output
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';

import { ReplaySubject, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { SelectInfiniteScrollSearchComponent } from '../../scrolls/select-infinite-scroll-search/select-infinite-scroll-search.component';

const TIME = 800;

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'filter-table',
  templateUrl: './filter-table.component.html',
  styleUrls: ['./filter-table.component.scss']
})
export class FilterTableComponent implements OnInit, OnDestroy {

  @ViewChild('selectInfinite', {static: false}) multiSelect: SelectInfiniteScrollSearchComponent;

  @Input() options: string[];
  @Input() column;
  @Input() hasCount: boolean;
  @Input() filterData: boolean;
  @Input() last: boolean;

  @Output() closed: EventEmitter<string[]> = new EventEmitter();
  @Output() emitReload: EventEmitter<string[]> = new EventEmitter();

  /** control for the selected option for multi-selection */
  public optionsMultiCtrl: FormControl = new FormControl([]);

  /** control for the MatSelect filter keyword multi-selection */
  public optionsMultiFilterCtrl: FormControl = new FormControl();

  /** list of options filtered by search keyword */
  public filteredOptionsMulti: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  public filterForm: FormGroup;

  constructor(private readonly formBuilder: FormBuilder) {
    this.filterForm = this.formBuilder.group({});
  }

  ngOnInit(): void {
    this.filterForm.addControl('filter', new FormControl(null, Validators.required));
    this.listenForSearchFieldValueChanges();
    this.filterForm.get('filter').setValue([]);
  }

  clearFilter(interno = true): void {
    this.filterForm.get('filter').setValue([]);
    if (interno) {
      this.emitReload.emit(this.filterForm.value);
    }
  }

  listenForSearchFieldValueChanges() {
    this.optionsMultiFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterOptions();
      });

    this.optionsMultiCtrl.valueChanges
      .pipe(
        debounceTime(TIME),
        distinctUntilChanged()
      )
      .subscribe((value: string[]) => {
        if (value) {
          this.closed.emit(value);
        }
      });
  }

  handleFilterChange(event) {
    this.filterForm.get('filter').setValue(event.checkedValue);
    this.emitReload.emit(event.emitValue);
  }

  handleClosed(): void {
    // this.closed.emit(this.optionsMultiCtrl.value)
  }

  private filterOptions(): void {
    if (!this.options) {
      return;
    }
    let search = this.optionsMultiFilterCtrl.value;
    if (!search) {
      this.filteredOptionsMulti.next(this.options.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredOptionsMulti.next(
      this.options.filter(bank => bank.toLowerCase().indexOf(search) > -1)
    );
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

}
