import { Component, Input, OnInit } from '@angular/core';
import { ExampleFlatNode, TreeNode } from '../../../../../utils/models/common.interface';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';

@Component({
  selector: 'tree-induxtry',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.scss']
})
export class TreeInduxtryComponent implements OnInit {

  @Input() tree: TreeNode[];

  lastOpenedNode:ExampleFlatNode;

  private readonly _transformer = (node: TreeNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
      disabled: node.disabled || false,
      link: node.link,
      icon: node.icon,
      tooltip: node.tooltip,
      open: node.open || false
    };
  };

  treeControl = new FlatTreeControl<ExampleFlatNode>(
    node => node.level,
    node => node.expandable,
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => node.children,
  );
  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;

toggleNode(node: ExampleFlatNode): void {
  this.treeControl.toggle(node);
}

openTree(node: ExampleFlatNode): void {
  if(this.treeControl.isExpanded(node)){
    this.treeControl.collapse(node)
  } else {
    this.treeControl.expand(node);
    this.lastOpenedNode = node;
  }
}

check(node: ExampleFlatNode, type?: string) {
  if(type === 'disabled'){
    if(node.disabled){
      return 'disabled'
    }
  } else {
    if(node === this.lastOpenedNode && this.treeControl.isExpanded(node)){
      return true;
    } else {
      return false;
    }

  }

}
  openNodesDefault() {
    const nodesOpen = this.treeControl.dataNodes.filter((res: any) => res.open === true);
    nodesOpen.forEach(item => {
      this.openTree(item);
    })
  }

  ngOnInit(): void {
    this.dataSource.data = this.tree;

    this.openNodesDefault();
  }

  handleButtonClick(event: Event, node?: ExampleFlatNode) {
    const clickedButton = event.currentTarget as HTMLButtonElement;

    if(node !== this.lastOpenedNode){
      clickedButton.style.backgroundColor = 'var(--primary-color-light)';
    }
    else {
      clickedButton.style.backgroundColor = 'var(--white)';
    }

    clickedButton.classList.add('color-transition');

    clickedButton.style.transition = '';

    setTimeout(() => {
      clickedButton.style.backgroundColor = 'transparent';
    }, 300);

    if(node === this.lastOpenedNode){
      this.lastOpenedNode = null;
    }

  }

}
