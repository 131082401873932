import { Warning } from "../../../utils/models/input.interface"

/** * FU - 001 - Retorna a propriedade do status *
 *
 * @param {Warning} warning - obj com a lista de status.
 * @returns {string} propriedade do status *
 * @throws {  } null.
 *
 * @example
 * const warning: Warning = {
 *  sucess: { active: true, text: 'success' },
 *  error: null,
 *  info: null,
 *  alert: null,
 *  load: null
 * }
 * const result = propertyObjWarning(warning)
 * result é igual a 'sucess'
 **/
export function propertyObjWarning(warning: Warning): string {
  let propertyWarning = 'default';
  if(warning.sucess){
    propertyWarning = 'sucess';
  } else if(warning.error){
    propertyWarning = 'error';
  } else if(warning.info){
    propertyWarning = 'info';
  } else if(warning.alert){
    propertyWarning = 'alert';
  } else if(warning.load){
    propertyWarning = 'load';
  }
  return propertyWarning;
}

/** * FU - 002 - Retorna o item pelo status especifico *
 *
 * @param {Warning} warning - obj com a lista de status.
 * @returns {string} Retorna o icone em que o status está inserido *
 * @throws {  } null.
 *
 * @example
 * const warning: Warning = {
 *  sucess: { active: true, text: 'success' },
 *  error: null,
 *  info: null,
 *  alert: null,
 *  load: null
 * }
 * const result = returnWarningIcon(warning)
 * result é igual a 'warning-sucess'
 **/
export function returnWarningIcon(warning: Warning): string {
  const porpertyIcon = propertyObjWarning(warning);
  const iconsWarning = {
    sucess: 'warning-sucess',
    error: 'warning-error',
    info: 'warning-circle-information',
    alert: 'warning-circle-alert',
    load: 'loading',
  };
  return iconsWarning[porpertyIcon] ? iconsWarning[porpertyIcon] : '';
}

/** * FU - 003 - Retorna a cor do texto da mensagem de erro *
 *
 * @param {Warning} warning - obj com a lista de status.
 * @returns {string} Retorna a cor em que o status está inserido *
 * @throws {  } null.
 *
 * @example
 * const warning: Warning = {
 *  sucess: { active: true, text: 'success' },
 *  error: null,
 *  info: null,
 *  alert: null,
 *  load: null
 * }
 * const result = returnColorWarning(warning)
 * result é igual a { 'color': '#115F22' }
 **/
export function returnColorWarning(warning: Warning): object {
  const porpertyIcon = propertyObjWarning(warning);
  const warningColors = {
    sucess: { 'color': '#115F22' },
    error: { 'color': '#A80B00' },
    info: { 'color': '#1F52AB' },
    alert: { 'color': '#5F4407' },
    load: { 'color': '#102956' },
  };
  return warningColors[porpertyIcon] ? warningColors[porpertyIcon] : {};
}

/** * FU - 004 - Retorna o texto pelo status especifico *
 *
 * @param {Warning} warning - obj com a lista de status.
 * @returns {string} Retorna o texto em que o status está inserido *
 * @throws {  } null.
 *
 * @example
 * const warning: Warning = {
 *  sucess: { active: true, text: 'success' },
 *  error: null,
 *  info: null,
 *  alert: null,
 *  load: null
 * }
 * const result = returnTextWarning(warning)
 * result é igual a 'success'
 **/
export function returnTextWarning(warning: Warning): string {
  const porpertyIcon = propertyObjWarning(warning);
  return warning[porpertyIcon] && warning[porpertyIcon].text ? warning[porpertyIcon].text : 'Warning Message';
}
