import { AfterViewChecked, Component, ElementRef, EventEmitter, Input, NgZone, OnChanges, OnInit, Output, Renderer2 } from '@angular/core';
import mermaid from 'mermaid';
import { ButtonClickedFlowchart, ButtonNodeFlowchart, NodeFlowchart } from '../../../../../utils/models/common.interface';

@Component({
  selector: 'flowchart',
  templateUrl: './flowchart.component.html',
  styleUrls: ['./flowchart.component.scss']
})
export class FlowchartComponent implements OnInit, OnChanges, AfterViewChecked {
  @Input() nodes: NodeFlowchart[];
  @Output() buttonClicked: EventEmitter<ButtonClickedFlowchart> = new EventEmitter<ButtonClickedFlowchart>();

  flowChart: any;
  stringFlowChart: any = '';
  listernerEvents: boolean;
  hasButtons: boolean;
  mermaidInstance: any;
  defaultValue = true;
  loadingFlowchart: boolean

  constructor(
    private readonly renderer: Renderer2,
    private readonly zone: NgZone,
    private readonly elementRef: ElementRef
  ) {}

  ngOnInit(): void {
    this.mermaidInstance = mermaid.initialize({ startOnLoad: false });
  }

  ngOnChanges(change): void {
    if (change?.nodes) {
      this.hasButtons = (this.nodes.filter(res => res.buttons).length > 0);
      this.createFlowchart(change.nodes.currentValue, !change.nodes.firstChange);
      if (!change.nodes.firstChange) {
        this.listernerEvents = true;
      }
    }
  }

  ngAfterViewChecked() {
    if (this.hasButtons) {
      this.zone.runOutsideAngular(() => {
        if (!this.listernerEvents) {
          this.addEventListenersFlowChart();
        }
      });
    }
  }

  addEventListenersFlowChart() {
    const buttonsFlowchart = this.elementRef.nativeElement.querySelectorAll('.js-event-click-btn-flowchart');
    if (buttonsFlowchart.length > 0) {
      buttonsFlowchart.forEach(item => {
        const nodeBtn = this.nodes.find(res => res.key === item.getAttribute('data-id'));
        this.renderer.listen(item, 'click', () => this.onClickButtonFlowchart(item.getAttribute('data-action'), nodeBtn));
      })
      this.listernerEvents = true;
    }
  }

  createButtonsFlowchart(buttons: ButtonNodeFlowchart[], key: string): string {
    let btns = '';
    buttons.forEach(btn => {
      btns += `
        <button
          class="js-event-click-btn-flowchart ${btn.class ? btn.class : 'button-flowchart'}"
          data-id="${key}"
          data-action="${btn.action}"
        >
          ${btn.title}
        </button>
      `;
    });
    return btns;
  }

  insertHtmlFlowchart(title: string, node: NodeFlowchart): string {
    let buttons = '';
    if (node.buttons) {
      buttons = this.createButtonsFlowchart(node.buttons, node.key);
    }
    return `
      <div
        class="node-flow-chart"
      >
        <p>${title}</p>
        ${buttons ? `<div>${buttons}</div>` : ''}
      </div>
    `;
  }

  onClickButtonFlowchart(action: string, node: NodeFlowchart): void {
    this.buttonClicked.emit({
      action,
      node
    });
  }

  mountObjectFlowchart(nodes: NodeFlowchart[]): string[] {
    let objFlowchart = [
      'flowchart LR'
    ];
    nodes.forEach(node => {
      if (node.parent) {
        const parents = nodes.filter(res => node.parent.indexOf(res.key) !== -1);
        parents.forEach(parent => {
          objFlowchart.push(`
          ${parent.key}(${this.insertHtmlFlowchart(parent.title, parent)}) --> ${node.key}(${this.insertHtmlFlowchart(node.title, node)})
          `)
        });
      } else {
        objFlowchart.push(`
          ${node.key}(${this.insertHtmlFlowchart(node.title, node)})
        `)
      }
    })
    return objFlowchart;
  }

  createFlowchart(nodes?: NodeFlowchart[], refresh?: boolean) {
    this.loadingFlowchart = true;
    if (refresh) {
      this.elementRef.nativeElement.querySelector('.mermaid').innerHTML = '';
      this.defaultValue = false;
      setTimeout(() => {
        this.defaultValue = true;
      }, 300);
    }
    this.zone.runOutsideAngular(() => {
      setTimeout(() => {
        this.flowChart = this.mountObjectFlowchart(nodes || this.nodes);
        this.stringFlowChart = this.flowChart.join("\n");
        this.elementRef.nativeElement.querySelector('.mermaid').innerHTML = this.stringFlowChart;
        mermaid.init();
        this.listernerEvents = false;
      }, 500);
    });
    setTimeout(() => {
      this.loadingFlowchart = false;
    }, 1200);
  }
}
