import {
  RequestAttribute,
  RequestParams,
} from './../../../../../../utils/models/http.interface';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../../../../environments/environment';
import { SelectInterface } from '../../../../../../utils/models/table.interface'

// Interface

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  private readonly apiUrl: string = environment.apiUrl;

  constructor(private readonly http: HttpClient) {}

  genericGetListTable<T>(endpoint: string, requestParamsDynamic: RequestParams, attrDynamic?: RequestAttribute[], filterAttributeDynamic?: RequestAttribute[]): Observable<T> {
    
    const url = `${localStorage.getItem('environment')}/${endpoint}`;
    let paramsDynamic
    if(requestParamsDynamic.sort?.length>0){
      paramsDynamic = new HttpParams()
      .set('offset', requestParamsDynamic.offset?.toString())
      .set('limit', requestParamsDynamic.limit?.toString())
      .set('sortBy',requestParamsDynamic.sort)
      .set('sortMode', requestParamsDynamic.order)
    }else{
      paramsDynamic = new HttpParams()
      .set('offset', requestParamsDynamic.offset?.toString())
      .set('limit', requestParamsDynamic.limit?.toString())
    }
    if (!!attrDynamic) {
      attrDynamic.forEach((att) => paramsDynamic = paramsDynamic.append(att.param, att.value));
    }
    if (!!filterAttributeDynamic) {
      filterAttributeDynamic.forEach((att) => paramsDynamic = paramsDynamic.append(att.param, att.value));
    }
    return this.http.get<T>(url, { params: paramsDynamic })
      .pipe(map((response: T) => {
        return response;
      }));
  }

  genericGetSelect(selectInterface?: SelectInterface): Observable<any>  {
    if (!selectInterface) {
      return of([]);
    }
    if (selectInterface.mockData !== undefined) {
      return of(selectInterface.mockData);
    } else {
      const url = `${selectInterface.apiUrl ? selectInterface.apiUrl : this.apiUrl}/${selectInterface.endpoint}`;
      let paramsDynamic = new HttpParams()
      if (!!selectInterface.attributes) {
        selectInterface.attributes.forEach((att) => paramsDynamic = paramsDynamic.append(att.param, att.value));
      }
      return this.http.get<any>(url, { params: paramsDynamic })
      .pipe(map((response: any) => {
        return response;
      }));
    }
  }
}
