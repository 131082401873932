import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';

import moment, { Moment} from 'moment';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { HeaderPickerComponent } from '../header-picker/header-picker.component';

export const YEAR_MODE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

const datePickerClass = {
  DAY: 'calendar-picker',
  MONTH: 'calendar-picker-month',
  YEAR: 'calendar-picker-year',
  PERIOD: 'calendar-picker-period'
}

@Component({
  selector: 'calendar-period',
  templateUrl: './calendar-period.component.html',
  styleUrls: ['./calendar-period.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'pt-br' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: YEAR_MODE_FORMATS },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CalendarPeriodComponent),
      multi: true,
    },
  ],
})
export class CalendarPeriodComponent implements ControlValueAccessor {

  headerPicker = HeaderPickerComponent;
  @Input() customFormFieldClass = '';
  @Input() label = '';
  @Output() selectedEverything = new EventEmitter();

  _maxPeriod: Moment;
  @Input()
  get max(): Date {
    return this._maxPeriod ? this._maxPeriod.toDate() : undefined;
  }
  set max(max: Date) {
    if (max) {
      const momentDatePeriod = moment(max);
      this._maxPeriod = momentDatePeriod.isValid() ? momentDatePeriod : undefined;
    }
  }

  _minPeriod: Moment;
  @Input()
  get min(): Date {
    return this._minPeriod ? this._minPeriod.toDate() : undefined;
  }
  set min(min: Date) {
    if (min) {
      const momentDateMinPeriod = moment(min);
      this._minPeriod = momentDateMinPeriod.isValid() ? momentDateMinPeriod : undefined;
    }
  }

  private _mode: 'PERIODO';
  @Input()
  get mode(): 'PERIODO' {
    return this._mode;
  }
  set mode(mode: 'PERIODO') {
    this._mode = mode;
    this._setupFilter();
  }

  @Input() touchUi = false;

  _customFilter: (d: Moment) => boolean;

  @ViewChild(MatDatepicker) _picker: MatDatepicker<Moment>;

  _startDate: FormControl = new FormControl();
  _endDate: FormControl = new FormControl();
  _panelClass = datePickerClass.PERIOD;
  _typeDate = 'start';

  // Function to call when the date changes.
  onChange = (date: Date) => {};

  // Function to call when the input is touched (when a star is clicked).
  onTouched = () => {};

  /** send the focus away from the input so it doesn't open again */
  _takeFocusAway = (datepicker: MatDatepicker<Moment>) => {};

  _writeValue(date: Date) {
    const momentDate = moment(date);
    if (momentDate.isValid()) {
      if (this._typeDate === 'start') {
        this._startDate.setValue(moment(date), { emitEvent: false });
      } else {
        this._endDate.setValue(moment(date), { emitEvent: false });
      }
    }
  }

  writeValue(date: Date): void {
    this._writeValue(date);
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // Allows Angular to disable the input.
  setDisabledState(isDisabled: boolean): void {
    this._picker.disabled = isDisabled;
    isDisabled ? this._startDate.disable() : this._startDate.enable();
  }

  _dateChangeHandler(chosenDate: Moment, typeDate: string) {
    this._typeDate = typeDate;
    this.onChange(chosenDate?.toDate());
    this.onTouched();
    if (this._startDate.value && this._endDate.value) {
      this.selectedEverything.emit({
        'start_period': this._startDate.value,
        'end_period': this._endDate.value
      });
    }
  }

  _openDatepickerOnClick(datepicker: MatDatepicker<Moment>) {
    if (!datepicker.opened) {
      this.setPanelClassPeriod(datePickerClass.PERIOD);
      datepicker.open();
      this.onTouched();
    }
  }

  private _setupFilter() {
    if(this.mode === 'PERIODO') {
      this._customFilter = (d: Moment) => {
        return !d.day();
      };
    }
  }
  setPanelClassPeriod(view) {
    if (this._panelClass === '') {
      this._panelClass = datePickerClass.PERIOD;
    } else {
      this._panelClass = view;
    }
  }

  get panelClass() {
    return this._panelClass;
  }

  capitalizeMonthPeriod() {
    const contentPeriod = document.getElementsByClassName('mat-calendar-body-cell-content') as HTMLCollectionOf<HTMLElement>;
    Array.from(contentPeriod).forEach((monthPeriod) => {
      monthPeriod.innerText = monthPeriod.innerText.toLowerCase();
      monthPeriod.innerText = monthPeriod.innerText.charAt(0).toUpperCase() + monthPeriod.innerText.slice(1);
    })
  }

  changePanelClassPeriod(eventPeriod) {
    if (eventPeriod === 'year') {
      this.setPanelClassPeriod(datePickerClass.MONTH);
      this.capitalizeMonthPeriod();
    } else if (eventPeriod === 'multi-year') {
      this.setPanelClassPeriod(datePickerClass.YEAR);
    } else {
      this.setPanelClassPeriod(datePickerClass.PERIOD);
    }
  }
}
