import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TextInput, Warning } from '../../../../../utils/models/input.interface';
import { returnColorWarning, returnTextWarning, returnWarningIcon } from '../../../functions/utilities.service';
@Component({
  selector: 'password-input',
  templateUrl: './password-input.component.html',
  styleUrls: ['./password-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: PasswordInputComponent,
    },
  ],
})
export class PasswordInputComponent implements ControlValueAccessor {
  warningIconPasswordInput = returnWarningIcon;
  colorWarningPasswordInput = returnColorWarning;
  textWarningPasswordInput = returnTextWarning;

  @Input() passwordInput: TextInput;

  @Input() warning: Warning = {
    sucess: null,
    error: null,
    info: null,
    alert: null,
    load: null
  };

  @Output() valueEmitter: EventEmitter<string> = new EventEmitter<string>();

  hide = true;


  writeValue(value: any): void {
    this.passwordInput.value = value;
  }

  onChange: (_: any) => void = () => { }

  onTouched = (value: unknown) => {
    return value;
  };

  registerOnChange(onChange: (value: any) => any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setValue() {
    this.onChange(this.passwordInput.value);
    this.valueEmitter.emit(this.passwordInput.value);
  }
}
