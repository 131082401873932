import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NumberInput, Warning } from '../../../../../utils/models/input.interface';
import { returnColorWarning, returnTextWarning, returnWarningIcon } from '../../../functions/utilities.service';

@Component({
  selector: 'number-input',
  templateUrl: './number-input.component.html',
  styleUrls: ['./number-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: NumberInputComponent,
    }
  ],
})
export class NumberInputComponent implements OnInit, ControlValueAccessor {
  warningIconNumberInput = returnWarningIcon;
  colorWarningNumberInput = returnColorWarning;
  textWarningNumberInput = returnTextWarning;

  @Input() numberInput: NumberInput;

  @Input() warning: Warning = {
    sucess: null,
    error: null,
    info: null,
    alert: null,
    load: null
  };

  showSpinner = false;
  oldValue = 0;

  @Output() valueEmitter: EventEmitter<number> = new EventEmitter<number>();

  constructor(private readonly toastr: ToastrService) { }

  upValue(event) {
    event.stopPropagation();
    const oldValue = this.numberInput.value;
    let newVal = oldValue;
    if (oldValue >= this.numberInput.maximo) {
      this.toastr.show('Valor máximo atingido.', 'Erro', { timeOut: 5000 , messageClass: 'error'});
    } else {
      newVal = oldValue + this.numberInput.step;
    }

    this.numberInput.value = newVal;
    this.onChange(this.numberInput.value);
    this.valueEmitter.emit(this.numberInput.value);
  }

  downValue(event) {
    event.stopPropagation();
    const oldValue = this.numberInput.value;
    let newVal = oldValue;
    if (oldValue <= this.numberInput.minimo) {
      this.toastr.show('Valor mínimo atingido.', 'Erro', { timeOut: 5000 , messageClass: 'error'});
    } else {
      newVal = oldValue - this.numberInput.step;
    }

    this.numberInput.value = newVal;
    this.onChange(this.numberInput.value);
    this.valueEmitter.emit(this.numberInput.value);
  }

  writeValue(value: any): void {
    this.numberInput.value = value;
  }

  onChange = (value: any) => {
    return value;
  };

  onTouched = (value: unknown) => {
    return value;
  };

  registerOnChange(onChange: (value: any) => any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  ngOnInit(): void {
    if(this.numberInput.value === undefined) {
      this.numberInput.value = 0;
    }
  }

  setNewValue(): void {
    if (!this.validValue()) {
      this.numberInput.value = this.oldValue;
    }

    this.onChange(this.numberInput.value);
    this.valueEmitter.emit(this.numberInput.value);
  }

  validValue(): boolean {
    if (this.numberInput.value > this.numberInput.maximo || this.numberInput.value < this.numberInput.minimo) {
      this.toastr.show('Valor não permitido.', 'Erro', { timeOut: 5000 , messageClass: 'error'});
      return false;
    } else {
      return true;
    }
  }

  canShowSpinner() {
    this.showSpinner = !this.showSpinner;
    this.oldValue = this.numberInput.value;
  }
}
