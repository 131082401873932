import { Component, Input, AfterViewInit, EventEmitter, Output } from '@angular/core';
import mapboxgl, { Map, NavigationControl } from 'mapbox-gl';
import { MapBoxService } from './service/mapBox.service';
import geoJSONMeso from 'src/utils/mocks/geoJsonMesor.json'
import geoJSONCity from 'src/utils/mocks/geoJsonCidadesMg.json'
@Component({
  selector: 'mapBox',
  templateUrl: './mapBox.component.html',
  styleUrls: ['./mapBox.component.scss']
})
export class MapBoxComponent implements AfterViewInit {
  map: Map;
  @Input() loadMapData: null;
  @Input() configMap: any;
  @Input() idMap = 'mapBoxChart';
  @Input() idPrint = 'mapContainer'
  @Input() type = 'Fill';
  @Input() unit = '%';
  @Input() reverseScale = false;

  @Output() clickPoint: EventEmitter<any> = new EventEmitter();

  labels = [100, 50, 0, -50, -100]
  maxMin
  dataMap

  constructor(
    private readonly mapBoxService: MapBoxService
  ) { }

  ngAfterViewInit(): void {
    if (this.loadMapData !== null) {
      this.loadMap(this.loadMapData);
    }
  }

  loadMap(info: any): void {
    const dadosBack = info.data;
    const geoJson: any = info.type === 'meso' ? geoJSONMeso : geoJSONCity
    const scale = info.scale
    const createObj: any = {
      features: [],
      type: 'FeatureCollection',
      color: [],
      info: []
    }

    this.maxMin = this.getMaxMin(dadosBack);
    const min = this.maxMin.min;
    const max = this.maxMin.max;

    dadosBack.map((element) => {
      const e = geoJson?.features.find((e) => {
        return e.properties.id === element.code
      })
      createObj?.features?.push(e);
      createObj?.info?.push(element);

      const res = element.value;
      let color;

      if (res < 0) {
        // Interpolação entre as duas primeiras cores para valores negativos
        const range = (min < 0) ? -min : 0; // Garante que o range seja não negativo
        const adjustedValue = (res - min) / range; // Normaliza o valor no intervalo de 0 a 1
        const colorIndex = Math.floor(adjustedValue * (2 - 1e-6)); // Assegura que o índice seja 0 ou 1
        const fator = (adjustedValue - colorIndex * 0.5) * 2; // Ajusta o fator para interpolação
        color = this.interpolarCor(scale[colorIndex], scale[colorIndex + 1], fator);
      } else if (res === 0) {
        color = scale[2];
      } else {
        // Interpolação entre as duas últimas cores para valores positivos
        const range = (max > 0) ? max : 1; // Garante que o range seja positivo e evita divisão por zero
        const adjustedValue = (res) / range; // Normaliza o valor no intervalo de 0 a 1
        const colorIndex = 2 + Math.floor(adjustedValue * (2 - 1e-6)); // Assegura que o índice seja 2 ou 3
        const fator = (adjustedValue - (colorIndex - 2) * 0.5) * 2; // Ajusta o fator para interpolação
        color = this.interpolarCor(scale[colorIndex], scale[colorIndex + 1], fator);
      }
      createObj?.color?.push(color);
    });

    this.setLayout(createObj, geoJson);
  }

  interpolarCor(cor1, cor2, fator) {
    const num2 = 2;
    const num1 = 1;
    const num16 = 16;
    const num5 = 5;
    const num3 = 3;
    let r1 = 0
    let g1 = 0
    let b1 = 0
    if (cor1 !== undefined) {
      r1 = parseInt(cor1.substr(num1, num2), num16);
      g1 = parseInt(cor1.substr(num3, num2), num16);
      b1 = parseInt(cor1.substr(num5, num2), num16);
    }
    let r2 = 0
    let g2 = 0
    let b2 = 0
    if (cor2 !== undefined) {
      r2 = parseInt(cor2.substr(num1, num2), num16);
      g2 = parseInt(cor2.substr(num3, num2), num16);
      b2 = parseInt(cor2.substr(num5, num2), num16);
    }

    const r = Math.round(r1 + (r2 - r1) * fator);
    const g = Math.round(g1 + (g2 - g1) * fator);
    const b = Math.round(b1 + (b2 - b1) * fator);

    return `#${(r < num16 ? '0' : '') + r.toString(num16)}${(g < num16 ? '0' : '') + g.toString(num16)
      }${(b < num16 ? '0' : '') + b.toString(num16)}`;
  }

  getMaxMin(data: any) {
    let maxValue = null;
    let minValue = null;

    data.forEach((element) => {
      if (maxValue === null || element.value > maxValue) {
        maxValue = element.value;
      }
      if (minValue === null || element.value < minValue) {
        minValue = element.value;
      }
    });

    maxValue = Math.ceil(maxValue + 10);
    minValue = Math.floor(minValue - 10);

    return { max: maxValue, min: minValue }
  }

  setLayout(createObj, geoJson) {
    new Promise((resolve) => {
      this.dataMap = createObj;
      resolve(createObj);
    }).then(() => {
      this.createMap();
    });
  }

  createMap() {
    //Gera o mapa
    this.map = new Map(this.configMap);

    //Gera os dados que serao plotados no mapa
    this.map.on('style.load', () => {
      this.updateColorBarLabels()
      if (this.type === 'Fill') {
        this.addCustomLayerTypeFill(this.dataMap);
      } else {
        this.addCustomLayerTypeCircle(this.dataMap);
      }
    });

    //Adiciona os controles de navegacao do mapa
    this.map.addControl(new NavigationControl({
      position: 'top-right'
    }));

    // Verificar se o estilo do mapa está completamente carregado
    this.map.on('load', (event) => {
      if (event.target.isStyleLoaded()) {
        this.changeZoomMsg();
        this.finishPlot();
      }
    });
  }

  addCustomLayerTypeFill(geoJson) {
    for (let i = 0; i < geoJson.features.length; i++) {
      this.map.addLayer({
        id: `Fill${i + 1}`,
        type: 'fill',
        source: {
          type: 'geojson',
          data: {
            features: [geoJson.features[i]],
            type: 'FeatureCollection'
          },
        },
        paint: {
          'fill-color': geoJson.color[i],
          'fill-outline-color': 'black'
        }
      });
    }
    this.mapHover(geoJson.info);
  }

  addCustomLayerTypeCircle(data) {
    this.map.addLayer({
      id: 'circles-layer',
      source: {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: data.map(function (item, i) {
            return {
              type: 'Feature',
              properties: {
                name: item.name,
                value: item.value,
                color: item.color
              },
              geometry: {
                type: 'Point',
                coordinates: [item.long, item.lat]
              },
            };
          })
        }
      },
      type: 'circle',
      paint: {
        'circle-radius': 6,
        'circle-color': ['get', 'color'] // Usando a cor diretamente do campo 'color'
      }
    });

    this.mapHover()
  }

  updateColorBarLabels() {
    if (this.maxMin !== null) {
      const min = this.maxMin?.min;
      const max = this.maxMin?.max;
      // Calcula os valores intermediários
      this.labels = [];
      const middle1 = Math.floor((max + 0) / 2);
      const middle2 = Math.floor((0 + min) / 2);

      // Atualiza as labels
      this.labels.push(max);
      this.labels.push(middle1);
      this.labels.push(0);
      this.labels.push(middle2);
      this.labels.push(min);
    }
  }

  finishPlot() {
    this.mapBoxService.finishEmit();
  }

  mapHover(dataBack?) {
    let id, info;
    const popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false
    });

    this.map.on('mousemove', (event) => {
      const states = this.map.queryRenderedFeatures(event.point, {
        layer: 'customLayerId'
      });

      id = states[0]?.source;
      info = states[0]?.properties;

      this.map.on('mouseenter', id, (e) => {
        this.map.getCanvas().style.cursor = 'pointer';
        let popupValue;
        let coordinates = []

        if (this.type === 'Fill') {
          //Pegas as lat/long no meio do layer
          const features = e.features[0].geometry.coordinates[0]
          const lat = features.reduce((acc, curr) => curr[1] + acc, 0) / features.length
          const lon = features.reduce((acc, curr) => curr[0] + acc, 0) / features.length
          coordinates.push(lon, lat)

          const description = dataBack.find((e) => e.code === info.id)
          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          }
          popupValue = `${description.city} ${description.value}%`

        } else {
          //Tipo Circle
          coordinates = e.features[0].geometry.coordinates
          popupValue = `Subestação ${e.features[0].properties.name}: ${e.features[0].properties.value}${this.unit}`
          this.map.on('click', id, (e) => {
            this.clickPoint.emit(e.features[0].properties.name)
            this.map.remove();
          });
        }
        popup.setLngLat(coordinates).setHTML(popupValue).addTo(this.map)
      });

      this.map.on('mouseleave', id, () => {
        this.map.getCanvas().style.cursor = '';
        popup.remove();
      });
    });

  }

  changeZoomMsg() {
    const div = document.querySelectorAll('.mapboxgl-scroll-zoom-blocker')

    div.forEach((item) => {
      item.innerHTML = '';
      const newContent = document.createElement('p');
      newContent.textContent = 'Use ctrl + scroll para utilizar o zoom';
      item.appendChild(newContent);
    })
  }

}
