import { Router } from '@angular/router';
import {
  Component,
  Input,
  EventEmitter,
  Output
} from '@angular/core';

import {
  Brand,
  PageTitle,
} from '../../../../../utils/models/layout.interface';
import { Observable } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map } from 'rxjs/operators';


@Component({
  selector: 'app-topnavbar',
  templateUrl: './topnavbar.component.html',
  styleUrls: ['./topnavbar.component.scss'],
})
export class TopnavbarComponent {
  @Input() pageTitles: PageTitle[];
  @Input() title: string;
  @Input() breakpoint: boolean;
  @Input() brand: Brand;
  @Input() link;
  @Output() collapse = new EventEmitter();
  @Output() changeGantt = new EventEmitter();

  @Input() drawer: any;

  fullScreen = false;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset).pipe(map(result => result.matches));

  constructor(
    public router: Router,
    private readonly breakpointObserver: BreakpointObserver,
  ) {
    if(!localStorage.getItem('environment')){
      this.router.navigate(['autenticacao/login/sistem-access'])
    }
  }

  selectTitle(path: string): Object {
  if(localStorage.getItem('environmentName') && this.pageTitles.find((page)=>{return path.includes(page.path)})?.name){
    return [localStorage.getItem('environmentName'),this.pageTitles.find((page)=>{return path.includes(page.path)}).name]
  }

   const tempPath = path.split(';')[0]
    return this.pageTitles.find((page) => page.path === tempPath)
      ? this.pageTitles.find((page) => page.path === tempPath).name.split('-')
      : null;
  }

  returnTitleTopNavbar(obj: any): string {
    return obj?.length > 0 ? obj[obj.length - 1].trim() : 'Casos de Teste';
  }

  toggleFullScreen(): void {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen().then(() => {
        this.fullScreen = true;
      });
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen().then(() => {
          this.fullScreen = false;
        });
      }
    }
  }

  sendCollapse() {
    this.collapse.emit(true);
  }

  openMenu(){
    this.drawer.toggle()
  }

  handleClick(value1, value2) {
    if(value1 === localStorage.getItem('environmentName')){
      this.router.navigate(['autenticacao/login/sistem-access']);
    }


    const indexOf = value2.indexOf(value1);
    const newArray = indexOf !== -1 ? value2.slice(0, indexOf + 1) : value2;

    let resultado = newArray.join('-');
    resultado = resultado.replace(/\s+$/, '');

    let breakpoint: string
    this.pageTitles.forEach((item,index) => {
      if(item.name === resultado){
        breakpoint = item.path
      }
    })
    if(breakpoint){
      this.router.navigate([breakpoint])
    }
  }

}
